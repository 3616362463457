<template>
  <div class="datasource-container">
    <v-card elevation="0" class="dense">
      <v-card-title
        >用户组成员
        <a @click.stop="show_sheet('edit', 'GROUPSACCOUNTDRAWER')"
          >(修改)</a
        ></v-card-title
      >
      <v-card-text>
        <v-simple-table dense>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left pl-4">
                  ID
                </th>
                <th class="text-left">
                  {{ $t("account.login_name") }}
                </th>
                <th class="text-left">
                  {{ $t("account.nickname") }}
                </th>
                <th class="text-left">
                  {{ $t("account.path") }}
                </th>
                <th class="text-center">
                  {{ $t("Actions") }}
                </th>
              </tr>
            </thead>
            <tbody v-if="accounts.length > 0">
              <tr v-for="item in accounts" :key="item.id" class="text-left">
                <td class="pl-4">{{ item.id }}</td>
                <td>{{ item.login_name || "-" }}</td>
                <td>{{ item.fullname || item.nickname || "-" }}</td>
                <td>{{ item.path || "-" }}</td>
                <td class="text-center">
                  <v-btn icon elevation="0" :to="`/account/view/${item.id}`">
                    <v-icon v-if="!item.loading">
                      mdi-clipboard-text-search-outline
                    </v-icon>
                  </v-btn>
                  <v-btn
                    icon
                    elevation="0"
                    color="red"
                    @click.stop="removeGroup(item)"
                    :disabled="item.loading || item.removed"
                  >
                    <v-icon v-if="!item.loading">
                      mdi-trash-can-outline
                    </v-icon>
                    <v-progress-circular
                      v-else
                      indeterminate
                      color="red"
                      size="24"
                      width="3"
                    ></v-progress-circular>
                  </v-btn>
                </td>
              </tr>
            </tbody>
            <tbody v-else>
              <tr class="text-center">
                <td
                  colspan="5"
                  style="width:100%;border-bottom:1px solid #E0E0E0;color:#7b7b7b"
                >
                  暂无数据
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-card-text>
    </v-card>
    <Component
      :is="sheet_editor"
      :show="drawer_status"
      :is_modify="current_edit_type === 'edit'"
      type="edit"
      :entity_info="group"
      v-if="group"
    />
    <ConfirmBox ref="confirm" />
  </div>
</template>

<script>
import { api_request } from "@/util/network";
import bus from "@/util/eventBus";
import ConfirmBox from "@/components/ConfirmBox";
import GroupsAccountDrawer from "@/views/group/drawer/GroupsAccountDrawer";

const EDITORS = {
  GROUPSACCOUNTDRAWER: GroupsAccountDrawer
};

export default {
  name: "GroupInfo",
  props: ["accounts", "group"],
  data() {
    return {
      // drawer
      current_editor: null,
      current_edit_type: "create",
      drawer_status: [false, false],
      sheet_editor: null
    };
  },
  methods: {
    show_sheet(edit_type, type) {
      this.sheet_editor = EDITORS[type];
      this.current_edit_type = edit_type;
      setTimeout(() => {
        this.$set(this.drawer_status, 0, true);
      }, 1);
    },
    removeGroup(account) {
      this.$set(account, "loading", true);

      this.$refs.confirm
        .showConfirm({
          contentText: `确认要将 ${account.nickname || account.login_name}(#${
            account.id
          }) 移出用户组？  `,
          callback: () => {
            return this.$http
              .post(`api/group/${this.$route.params.id}/accounts/remove`, {
                account_ids: [account.id]
              })
              .delegateTo(api_request)
              .then(() => {
                this.$set(account, "removed", true);
                bus.$emit("refresh");
                return `已成功移出用户组 ${account.nickname ||
                  account.login_name}(#${account.id})`;
              })
              .catch(({ code, message }) => {
                throw `移出用户组失败：${this.$t(
                  "api." + code
                )}, 额外信息: ${this.$t("api." + JSON.stringify(message))}`;
              });
          }
        })
        .catch(() => {
          account.loading = false;
        })
        .finally(() => {
          account.loading = false;
        });
    }
  },
  components: {
    ConfirmBox,
    GroupsAccountDrawer
  }
};
</script>

<style lang="less" scoped>
.datasource-container {
  width: 100%;
  box-sizing: border-box;
  .every-item {
    display: flex;
    height: 50px;
    align-items: flex-start;
    margin-top: 20px;
    label {
      flex: 0 0 140px;
      text-align: left;
      color: #666;
    }
    .input-item {
      input {
        width: 400px;
        height: 30px;
        box-sizing: border-box;
        padding-left: 20px;
        outline: none;
        border-bottom: 1px solid #949494;
        font-size: 16px;
        &.active {
          border-bottom: none;
        }
      }

      .prompt {
        font-size: 12px;
        color: #666;
      }
    }
  }

  .submit-wrapper {
    width: 40%;
    text-align: center;
    margin-top: 50px;
    .submitbtn {
      width: 120px;
      height: 40px;
      line-height: 40px;
    }
  }
}

/deep/.v-data-table > .v-data-table__wrapper > table > tbody > tr > td,
.v-data-table > .v-data-table__wrapper > table > tbody > tr > th,
.v-data-table > .v-data-table__wrapper > table > thead > tr > td,
.v-data-table > .v-data-table__wrapper > table > thead > tr > th,
.v-data-table > .v-data-table__wrapper > table > tfoot > tr > td,
.v-data-table > .v-data-table__wrapper > table > tfoot > tr > th {
  padding: 0 0px;
}
</style>
